"use client";

import { Header, HeaderTitle, LogoPrimary } from "@/app/_components/header";
import Link from "next/link";
import LogoutButton from "@/app/(partner)/_components/LogoutButton";
import { PartnerSidebarMobile } from "../partnerSidebar";

interface PartnerNavbarProps {
  partnerName: string;
}

const PartnerNavbar: React.FC<PartnerNavbarProps> = ({ partnerName }) => (
  <Header widthFull sticky className="max:px-8">
    <Link href="/">
      <LogoPrimary />
    </Link>
    <div className="flex w-full grow items-center justify-between gap-2 truncate">
      <HeaderTitle className="truncate">{partnerName}</HeaderTitle>
      <LogoutButton type="Button" />
      <div className="flex laptop:hidden">
        <PartnerSidebarMobile />
      </div>
    </div>
  </Header>
);

export default PartnerNavbar;
