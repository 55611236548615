import { cn } from "@/lib/utils";

interface HeaderProps extends React.PropsWithChildren<{}> {
  sticky?: boolean;
  className?: string;
  widthFull?: boolean;
}

export default function Header({
  children,
  sticky = false,
  className = undefined,
  widthFull = false,
}: HeaderProps) {
  return (
    <header
      className={cn(
        "z-100 flex w-full items-center bg-background px-4 py-2 shadow-lg tablet:h-14 tablet:px-5 laptop:h-16 laptop:px-8 desktop:px-8 max:px-[120px]",
        sticky && "sticky top-0",
        className,
      )}
    >
      <div
        className={`mx-auto flex h-8 w-full ${
          widthFull ? "" : "max-w-screen-max"
        } flex-1 items-center justify-between gap-4 tablet:gap-6 laptop:h-10 laptop:gap-8 desktop:h-12 desktop:gap-10`}
      >
        {children}
      </div>
    </header>
  );
}
