import { ButtonRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { IconName } from "@/app/_components/icon";

export interface ButtonProps {
  className?: string;
  size?: "small" | "medium" | "large";
  isDisabled?: boolean;
  leadingIcon?: IconName;
  trailingIcon?: IconName;
  variant?: "primary" | "secondary" | "tertiary" | "link";
  fill?: boolean;
}

const useButton = (props: ButtonProps) => {
  const { className, variant, size, fill, leadingIcon, trailingIcon } = props;
  const hasIcon = !!(leadingIcon || trailingIcon);

  const buttonClassName = ({
    isHovered,
    isPressed,
    isDisabled,
    isFocused,
    isFocusVisible,
  }: ButtonRenderProps) =>
    twMerge(
      "relative inline-flex items-center justify-center gap-3 font-extrabold whitespace-nowrap",
      "cosma:rounded-lg cosma:text-text-1000",
      classNames([
        // common styles
        {
          "outline-none": isFocused && !isFocusVisible,
          "outline-offset-1": isFocusVisible,
          "w-full": fill,
        },
        variant !== "link" && {
          "h-11": size === "small",
          "h-12": size === "medium",
          "h-14": size === "large",
          "h-11 tablet:h-12 desktop:h-14": size === undefined,
        },
        // primary styles
        variant === "primary" && [
          "bg-primary-500 text-white",
          {
            "px-8": !hasIcon,
            "pl-5 pr-6": leadingIcon && !trailingIcon,
            "pl-6 pr-5": trailingIcon && !leadingIcon,
            "px-5": leadingIcon && trailingIcon,
            "bg-primary-800 cosma:bg-primary-hover": isHovered,
            "bg-primary-900 cosma:bg-primary-hover": isPressed,
            "bg-primary-disabled cosma:text-text-disabled": isDisabled,
          },
        ],
        // secondary styles
        variant === "secondary" && [
          "border-2 border-primary-500 bg-white text-primary-500",
          {
            "px-6": !hasIcon,
            "pl-5 pr-6": leadingIcon,
            "pl-6 pr-5": trailingIcon,
            "bg-primary-50": isHovered,
            "border-primary-800 bg-primary-50 text-primary-800": isPressed,
            "border-primary-300 text-primary-300": isDisabled,
          },
        ],
        // tertiary styles
        variant === "tertiary" && [
          "text-primary-500 cosma:font-normal",
          {
            "text-primary-700": isHovered,
            "text-primary-900": isPressed,
            "text-neutral-500": isDisabled,
          },
        ],
        variant === "link" && [
          "text-primary-500 cosma:font-normal cosma:gap-2",
          {
            underline: !hasIcon,
            "text-sm gap-2": size === "small",
            "text-sm gap-2 tablet:text-base tablet:gap-3": size === undefined,
            "text-primary-700 cosma:underline cosma:decoration-2 cosma:underline-offset-[6px]":
              isHovered,
            "text-primary-900": isPressed,
            "text-neutral-500": isDisabled,
          },
        ],
      ]),
      className,
    );

  return { ...props, className: buttonClassName };
};

export default useButton;
